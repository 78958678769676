import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["fieldsFrame", "serviceId", "date", "amountPer", "count", "total"];

  serviceIdTarget;
  dateTarget;
  fieldsFrameTarget;

  amountPerTarget;
  hasCountTarget;
  countTarget;
  hasTotalTarget;
  totalTarget;

  connect() {
    this.calculate();

    const observer = new MutationObserver(() => this.calculate());
    observer.observe(this.fieldsFrameTarget, {
      childList: true,
      subtree: true
    });
  }

  updateFields(event) {
    const serviceId = this.serviceIdTarget.value;
    const date = this.dateTarget.value;
    const url = `/charges/update_fee_fields?service_id=${serviceId}&date=${date}`;
    this.fieldsFrameTarget.src = url;
  }

  calculate() {
    if (!this.hasTotalTarget) { return; }

    const amountPer = parseFloat(this.amountPerTarget.value) || 0;
    const count = this.hasCountTarget ? parseFloat(this.countTarget.value) || 0 : 1;
    const value = amountPer * count;
    this.totalTarget.textContent = value.toFixed(2);
  }
}
