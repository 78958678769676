import $ from 'jquery'

$(document).on('turbo:load', onLoad)

function onLoad() {
  $("#billing-report-runs-form .select-all").click(function (event) {
    event.preventDefault();
    $('.protocol-checkbox').prop('checked', true);
    updateCounter();
    return false;
  });

  $("#billing-report-runs-form .clear-all").click(function (event) {
    event.preventDefault();
    $('.protocol-checkbox').prop('checked', false);
    updateCounter();
    return false;
  });

  $('.protocol-checkbox').change('checked', function() {
    updateCounter();
  });
}

function updateCounter() {
  const count = $('.protocol-checkbox:checked').length;
  $('#selected-count').text(count + " Selected");
}